<template>
  <div>
    <LazyAppMobileAppDownloadBanner v-if="showAppBanner" />
    <LazyAppMasquerade v-if="!isWebView && isMasquerading" />
    <slot />
  </div>
</template>

<script setup lang="ts">
/**
 * This layout is mainly used for webview pages
 * or pages that don't need a header and footer
 */

usePartytown()

const { isWebView } = usePlatform()
const { isMasquerading } = useMasquerade()
const { showAppBanner } = useAppBanner()
</script>
